import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import localizedCategoriesByLocaleQuery from '@aurora/shared-client/components/community/LocalizedCategoriesByLocale.query.graphql';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import IntlWrapperContext from '@aurora/shared-client/components/context/IntlContext/IntlWrapperContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import { useLanguageText } from '@aurora/shared-client/components/languages/UseLanguageText/useLanguageText';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import { offsetPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import TextHelper from '@aurora/shared-client/i18n/TextHelper/TextHelper';
import Icons from '@aurora/shared-client/public/static/graphics/processed/enums';
import type { CategoryPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import type { RouterAndLink } from '@aurora/shared-client/routes/useCustomRouter';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  LocalizedCategoriesByLocaleQuery,
  LocalizedCategoriesByLocaleQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { EndUserPages, EndUserComponent } from '@aurora/shared-types/pages/enums';
import IntlCookieHelper from '@aurora/shared-utils/helpers/i18n/IntlCookieHelper';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import localStyles from './LanguagePicker.module.pcss';
import NavbarContext from '@aurora/shared-client/components/context/NavbarContext/NavbarContext';

const log = getLog(module);

interface Props {
  /**
   * Whether to show the icon.
   */
  useIcon?: boolean;
  /**
   * Whether to show the label.
   */
  useLabel?: boolean;
}

/**
 * Language picker that allows the user to change the language of the community.
 * @author Martin Sandoval
 */
const LanguagePicker: React.FC<Props> = ({ useIcon, useLabel }) => {
  const cx = useClassNameMapper(localStyles);
  const { locale, setLocale } = useContext(IntlWrapperContext);
  const { textLastModified } = useContext(AppContext);
  const { getLanguageText, loading: languageTextLoading, refetchLanguageText } = useLanguageText();
  const i18n = useTranslation(EndUserComponent.LANGUAGE_PICKER);
  const { formatMessage, loading: textLoading, FormattedMessage, intl, refetch } = i18n;
  const { router }: RouterAndLink<EndUserPages, EndUserQueryParams> = useEndUserRoutes();
  const { clearToasts, addAndRemoveToasts } = useToasts();

  const client = useApolloClient();
  const [, setShowLanguagePickerToast] = useGlobalState(GlobalStateType.SHOW_LANGUAGE_PICKER_TOAST);
  const getDropdownMenuPosition = useContext(NavbarContext);

  const {
    data: localizedCategoriesData,
    loading: localizedCategoriesLoading,
    error: localizedCategoriesError
  } = useQueryWithTracing<
    LocalizedCategoriesByLocaleQuery,
    LocalizedCategoriesByLocaleQueryVariables
  >(module, localizedCategoriesByLocaleQuery, {
    fetchPolicy: 'cache-first'
  });

  /**
   * Adds a language picker toast to the DOM
   */
  function addLanguagePickerToast(
    newLanguage: string,
    oldLanguage: string,
    languagePickerBundle?: Record<string, string>,
    languageTextBundle?: Record<string, string>
  ): void {
    const newLocalizedCategory = localizedCategoriesData.localizedCategoriesByLocale.find(
      localizedCategory => localizedCategory.locale === newLanguage
    );

    const oldLocalizedCategory = localizedCategoriesData.localizedCategoriesByLocale.find(
      localizedCategory => localizedCategory.locale === oldLanguage
    );
    const toastProps: ToastProps = {
      id: `LanguagePicker-${newLanguage}-success`,
      toastVariant: ToastVariant.FLYOUT,
      alertVariant: ToastAlertVariant.INFO,
      title: formatMessage(
        'toast.successTitle',
        {
          language: getLanguageText(newLanguage, languageTextBundle)
        },
        languagePickerBundle
      ),
      persistRouteChange: false,
      message: () => {
        return (
          <FormattedMessage
            id="toast.successMessage"
            values={{
              newLanguage: getLanguageText(newLanguage, languageTextBundle),
              oldLanguage: getLanguageText(oldLanguage, languageTextBundle),
              link: chunks => (
                <Button
                  variant={ButtonVariant.UNSTYLED}
                  className={cx('lia-g-link lia-g-text-sm')}
                  onClick={async () => {
                    clearToasts();
                    setShowLanguagePickerToast(previousState => ({
                      ...previousState,
                      showToast: false
                    }));
                    await router.pushRoute<CategoryPageAndParams>(
                      EndUserPages.CategoryPage,
                      {
                        categoryId: newLocalizedCategory?.category?.category?.displayId
                      },
                      {},
                      { shallow: true }
                    );
                  }}
                >
                  {chunks}
                </Button>
              ),
              backToLanguageLink: chunks => (
                <Button
                  variant={ButtonVariant.UNSTYLED}
                  className={cx('lia-g-link lia-g-text-sm')}
                  onClick={async () => {
                    clearToasts();
                    IntlCookieHelper.setLocalizedCategoryLocaleCookie(oldLanguage);
                    setLocale(oldLanguage);
                    setShowLanguagePickerToast(previousState => ({
                      ...previousState,
                      showToast: false
                    }));
                    await router.pushRoute<CategoryPageAndParams>(
                      EndUserPages.CategoryPage,
                      {
                        categoryId: oldLocalizedCategory?.category?.category?.displayId
                      },
                      {},
                      { shallow: true }
                    );
                  }}
                >
                  {chunks}
                </Button>
              )
            }}
            bundleOverride={languagePickerBundle}
          />
        );
      }
    };
    const previousToastId: string = `LanguagePicker-${oldLanguage}-success`;
    addAndRemoveToasts([toastProps], [previousToastId]);
  }

  if (languageTextLoading || textLoading || localizedCategoriesLoading) {
    return null;
  }

  if (localizedCategoriesError) {
    log.error('Error getting localized categories mapping', localizedCategoriesError);
  }

  const allowedLanguages = localizedCategoriesData?.localizedCategoriesByLocale
    .filter(localizedCategory => localizedCategory.category !== null)
    ?.map(localizedCategory => localizedCategory.locale)
    .sort((a, b) => getLanguageText(a).localeCompare(getLanguageText(b)));

  async function handleOnSelect(newLanguage: string) {
    // if new language is the same as the current language, do nothing
    if (newLanguage === locale) {
      return;
    }

    try {
      const [result, resultLanguageText] = await Promise.all([
        refetch(newLanguage),
        refetchLanguageText(newLanguage)
      ]);

      const languageTextBundle = resultLanguageText?.data?.cachedText[0]?.value;
      const languagePickerBundle = result?.data?.cachedText[0]?.value;

      await TextHelper.rebuildCaches(client, intl, textLastModified);

      IntlCookieHelper.setLocalizedCategoryLocaleCookie(newLanguage);
      addLanguagePickerToast(newLanguage, locale, languagePickerBundle, languageTextBundle);
      setLocale(newLanguage);
    } catch (error) {
      log.error('Error updating language', error);
    }
  }

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={Button}
        variant={ButtonVariant.UNSTYLED}
        data-testid="LanguagePicker.Toggle"
        className={cx('lia-toggle')}
        aria-label={formatMessage('dropdown.title')}
      >
        <Icon
          className={cx('lia-icon', { 'lia-visible-on-sm': !useIcon })}
          testId={'LanguagePicker.Icon'}
          icon={Icons.LanguageIcon}
          size={IconSize.PX_24}
        />
        {useLabel && (
          <small className={cx('lia-language-label')} data-testid={'LanguagePicker.Label'}>
            {getLanguageText(locale)}
          </small>
        )}
        <Icon icon={Icons.ChevronDownIcon} size={IconSize.PX_14} className={cx('lia-icon')} />
      </Dropdown.Toggle>
      <Dropdown.Menu
        align="right"
        popperConfig={offsetPopperConfig(0, getDropdownMenuPosition(7))}
        renderOnMount
      >
        {allowedLanguages.map(option => {
          return (
            <Dropdown.Item
              data-testid={`LanguagePicker.Item.${option}`}
              name={option}
              key={option}
              active={option === locale}
              onSelect={() => handleOnSelect(option)}
              aria-label={option === locale ? formatMessage('dropdown.title') : ''}
            >
              {getLanguageText(option)}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguagePicker;
