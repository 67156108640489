import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type {
  ForwardRefExoticComponent,
  MouseEventHandler,
  PropsWithoutRef,
  RefAttributes,
  TouchEventHandler
} from 'react';
import React, { forwardRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import NavbarAnchor from '../NavbarAnchor/NavbarAnchor';
import type { MenuLinkItem } from '../NavbarLink/NavbarLink';
import localStyles from './NavbarDropdownToggle.module.pcss';

interface Props {
  onClick: MouseEventHandler;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  onTouchStart: TouchEventHandler;
  onTouchEnd: TouchEventHandler;

  /** Whether the link should be clickable */
  enableAnchor: boolean;

  /** The MenuItem to render */
  titleLink: MenuLinkItem;

  /** Whether the dropdown is currently open */
  isOpen: boolean;

  /** Id for the outer element */
  id: string;
}

/**
 * A custom Toggle for a Navbar Dropdown that behaves as both a link and a dropdown toggle,
 * depending on how it is interacted with.
 * @author Rosalyn Rowe
 */
const NavbarDropdownToggle: ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<HTMLDivElement>
> = forwardRef(function CustomToggleForward(
  {
    enableAnchor,
    isOpen,
    titleLink,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onTouchStart,
    onTouchEnd,
    id
  },
  ref
) {
  const cx = useClassNameMapper(localStyles);
  const i18n = useTranslation(EndUserComponent.NAVBAR_DROPDOWN_TOGGLE);
  const { loading: textLoading, formatMessage } = i18n;

  if (textLoading) {
    return null;
  }

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    <div
      aria-haspopup={true}
      aria-label={!isOpen ? formatMessage('ariaLabelClosed') : ''}
      data-testid="NavbarDropdown.Toggle"
      tabIndex={-1}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      ref={ref}
      id={id}
    >
      <NavbarAnchor
        disabled={!enableAnchor}
        icon={Icons.ChevronDownIcon}
        iconClassName={cx('lia-g-navbar-dropdown-icon')}
        item={titleLink}
        className={cx('lia-g-navbar-link lia-link-extender')}
        wrapText
      />
    </div>
  );
});

export default NavbarDropdownToggle;
