import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { CommunityPoliciesFragment } from '@aurora/shared-generated/types/graphql-types';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { useContext } from 'react';

/**
 * Helper function used to determine whether the NotesIcon will render. Used to hide unnecessary markup in a
 * parent element.
 *
 * @author Jonathan Bridges
 */
export default function useWillNotesIconRender(): boolean {
  const { isFullyRegistered } = useRegistrationStatus();
  const { community } = useContext(AppContext);
  const { data } = useNodePolicies(
    module,
    {
      id: community?.id,
      useCanUsePrivateNotes: true
    },
    !isFullyRegistered
  );

  const isPrivateNotesAvailable = checkPolicy(
    (data?.coreNode as unknown as CommunityPoliciesFragment)?.communityPolicies?.canUsePrivateNotes
  );

  return isFullyRegistered && isPrivateNotesAvailable;
}
